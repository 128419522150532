body {
  /* background-color: gray; */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sigFullcontainer {
  width: 100%;
  height: 100%;
  top: 10%;
  left: 10%;
}

.sigContainer {
  /* width: 80%; */
  border-radius: 16px;
  overflow: hidden;
  height: 60%;
  margin: 0 auto;
  background-color: #f5f5f9;
}

.sigPad {
  width: 100%;
  height: 80%;
 
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 250px 80px;
  width: 250px;
  height: 80px;
  background-color: white;
  margin-bottom: 12px;
  border: 2px dotted #c0c3f1;
  border-radius: 16px;
  overflow: hidden;padding: 12px;
}
