.faq-question {
    margin-bottom: 20px;
  }
  
  .question {
    cursor: pointer;
    font-weight: bold;
  }
  
  .answer {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    transition: height 0.3s;
  }
  
  .answer-enter {
    height: 0;
    opacity: 0;
  }
  
  .answer-enter-active {
    height: auto;
    opacity: 1;
  }
  
  .answer-exit {
    height: auto;
    opacity: 1;
  }
  
  .answer-exit-active {
    height: 0;
    opacity: 0;
  }
  